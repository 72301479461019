import React from 'react';
import grey from '@mui/material/colors/grey';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

interface JFCircularProgressProps {
  size?: number;    
  radius?: string; 
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      borderStyle: 'solid',
      borderRadius: '50%',
      animation: 'loading-indicator-spinner 1.5s linear infinite'
    }
  })
);

const JFCircularProgress: React.FC<JFCircularProgressProps> = ({ size = 50, radius = '5px', style }) => {
  const styles = useStyles();

  return (
    <>
      <style>
        {`@keyframes loading-indicator-spinner {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }`}
      </style>
      <div
        className={styles.loader}
        style={{
          border: `${radius} solid ${grey['200']}`,
          borderTop: `${radius} solid black`,
          width: size,
          height: size,
          ...style
        }}
      />
    </>
  );
};

export default JFCircularProgress;
